















































































































































































@import '@/assets/css/_custom_checkbox.scss';
.overlay {
  display: none;
  &.disabled {
    display: block;
  }
}

.filter-answer {
  transition: all 1ms;

  &.inactive {
    opacity: 0.5;
  }
  &.disabled {
    pointer-events: none;
    opacity: 1;
    .v-input--selection-controls__ripple:before {
      background: white;
      border-radius: 0;
      content: '-';
      position: absolute;
      opacity: 1;
      width: 100%;
      height: 13px;
      top: 11px;
    }
  }
  input {
    &.inactive {
      opacity: 0.5;
    }
    &.disabled {
      opacity: 0.5;
      &:before {
        background: white;
        border-radius: 0;
        position: absolute;
        opacity: 1;
        width: 100%;
        height: 3px;
        top: 11px;
      }
    }
  }
  .v-label {
    color: white !important;
  }
  .v-icon {
    color: white !important;
  }
}
.styled-checkbox {
  .v-label {
    color: white !important;
  }
  .v-icon {
    color: white !important;
  }
}
