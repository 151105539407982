







































































































































































































































































































































































































































































#left-nav-wrapper {
  .v-list__tile__action {
    min-width: 40px;
  }
  .v-navigation-drawer--mini-variant .v-list__tile__action {
    min-width: 48px;
  }
}
