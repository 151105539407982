.styled-checkbox {
    position: absolute; // take it out of document flow
    transition: all 1ms;
    opacity: 0; // hide it
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-size:14px;
    }
  
    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: #222;
      border:2px solid white
    }
  
    // Box focus
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
  
    // Box checked
    &:checked + label:before {
      border-radius: 3px;
      background: #FFF;
    }
  
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &.disabled:not(:checked) + label:before {
      box-shadow: none;
       content: url('../../../../assets/images/cb-disabled.png');
    }
  
    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: #222;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #000, 4px 0 0 #000, 4px -2px 0 #000, 4px -4px 0 #000,
        4px -6px 0 #000, 4px -8px 0 #000;
      transform: rotate(45deg);
    }
  }
  
.dragnaddrop {
  display: inline-block;
  margin-left: 20px;
  color: #999;
}
.creategroup {
  border: 1px solid #999;
  border-radius: 20px;
}
.rename {
  background: white;
  color: black;
  position: absolute;
  top: 9px;
  left: 45px;
}
.answer,
.group {
  cursor: pointer;
  &:hover {
    background: #555;
  }
}
.group-button {
  color: #999999;
  font-size: 12px;
  &:hover {
    color: white;
  }
}
.groupname {
  position: relative;
  margin-left: 30px;
  .group-button {
    visibility: hidden;
  }

  &:hover {
    .group-button {
      visibility: visible;
    }
  }
}
.theme--dark.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #333;
}
.v-list.theme--dark {
  background: #333;
}
.text-14 {
  font-size: 14px;
}
.lowercase {
  text-transform: none !important;
  opacity: 0.57;
}
.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
  margin-bottom: 0;
}
.draggable-answer {
  .v-label {
    color: white !important;
  }
  .v-icon {
    color: white !important;
  }
}