#segment-container,
#compare-segments-container {
  .close-btn .v-btn__content {
    align-items: flex-end;
    justify-content: flex-end;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .active {
    background: #333;

    .theme--dark.v-icon {
      color: #fff !important;
    }
    .accent--text {
      color: white !important;
      caret-color: white !important;
    }
    .close-btn .v-btn__content {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .dragger {
    background: #555;
  }
  .text-black {
    color: black !important;
  }
  .bg-black {
    background: #222 !important;
    color: white !important;
  }
  .segment-container {
    background: #222 !important;
    position: fixed;
    height: 100%;
  }
  .filterstitle {
    border-bottom: 1px solid #333;
  }
  .header {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    line-height: 45px;
    background: #cd235c;
    padding: 0;
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }

  .footer {
    position: absolute;
    top: calc(100vh - 141px);
    z-index: 2;
    right: 0;
    width: 100%;
    height: 48px;
    padding: 0;
    background: #222;
    color: black;
  }

  .wrapper {
    position: relative;
    z-index: 1;
    top: 0;
    bottom: 48px;
    right: 0;
    width: 100%;
    background: #222;
    overflow: hidden;
    color: white;
    height: 100%;
  }

  .scroller {
    position: relative;
    background: #222;
    z-index: 1;
    width: 100%;
    transform: translateZ(0);
    -ms-user-select: none;
    user-select: none;
    text-size-adjust: none;
    height: calc(100vh - 135px);

    &.open {
      margin-top: 50px;
    }
  }
  .scroller li {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  .answer-container {
    height: 1px;
    overflow: hidden;

    &.selected {
      height: auto;
    }
  }
  .scroll-content-segs {
    height: 100%;
    overflow-y: auto;
    .v-input__control {
      width: 100%;
    }
  }

  .option-holder {
    position: relative;
    width: 100%;

    &:after {
      content: attr(data-count);
      position: absolute;
      right: 0;
      top: 0;
      color: white;
    }
  }
}
