







































































































































































.loading-page-close {
  .theme--light.v-icon {
    color: #fff;
  }
}
