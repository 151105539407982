








































































































































































































































































@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';

.v-breadcrumbs {
  padding-left: 0;
}

a {
  @include headline-small-strong;
}

#breadcrumbs {
  /* margin-left: $spacing-base; */
}
