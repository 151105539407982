#my-alert {
  position: relative;
  color: #ff8773 !important;
  // border-color: #333 !important;
  margin-left: 30px !important;
  .v-alert.v-alert--outline {
    // border: 1px solid #333 !important;
  }
  .v-icon {
    display: none;
  }
  &:before {
    content: url("../../../../assets/images/access_alarm_24px.svg");
    width: 30px;
    height: 30px;
    position: absolute;
    display: inline-block;
    left: -20px;
    top: 20px;
  }

  &.segmentfilter {
    &:before {
      top: 55px;
    }
  }
}
