


















































































































































@import '@/assets/css/_vars.scss';

.arcadia-createsurvey-dialog {
  overflow: visible;

  .access-form-buttons {
    margin-top: 33px;
    display: flex;
    justify-content: flex-end;
  }

  .contact-container {
    padding: $spacing-base;
  }

  .contact-overlay {
    overflow: visible;
  }

  .contact-btn {
    margin-left: 0;
  }
}
