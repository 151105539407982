@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';

.theme--light.v-text-field--outline > .v-input__control > .v-input__slot { // input select border
	// border: 1px solid color('rule-dark');
	border-width: 1px;
}

.theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover { // input select border on hover
	// border: 1px solid color('rule-dark');
	border-width: 1px;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot { // square dropdown
	border-radius: 0px;
}

.theme--light.v-text-field--solo.dropdown-round > .v-input__control > .v-input__slot { // round dropdown
	border-radius: 30px;
}

.v-select__selections, .v-select__slot, .v-label {
	margin-left: 5px;
	@include body-strong;
}

.v-text-field--outline > .v-input__control > .v-input__slot {
	max-height: 36px;
	min-height: 36px;
}

.v-text-field--box > .v-input__control > .v-input__slot {
	min-height: 40px;
}