@import '@/assets/css/_vars.scss';
@import '@/assets/css/_functions.scss';

//typography
@mixin headline-title {
  font-family: $font-primary;
  font-size: $font-size-base * 1.5;
  font-weight: $font-bold;
  // text-transform: $font-case;
  color: color('font-dark');
}

@mixin headline-small-strong {
  font-family: $font-primary;
  font-size: $font-size-base;
  font-weight: $font-bold;
  // text-transform: $font-case;
  color: color('font-dark');
}

@mixin headline-small-soft {
  font-family: $font-primary;
  font-size: $font-size-base;
  font-weight: $font-bold;
  // text-transform: $font-case;
  color: color('font-medium-light');
}

// used in most h2
@mixin headline-strong {
  font-family: $font-primary;
  font-size: 18px;
  line-height: 22px;
  font-weight: $font-bold;
  color: color('font-dark');
}

@mixin body-strong {
  font-family: $font-primary;
  font-size: $font-size-base;
  font-weight: $font-regular;
  // text-transform: $font-case;
  color: color('font-dark');
}

@mixin body-soft {
  font-family: $font-primary;
  font-size: $font-size-base;
  font-weight: $font-regular;
  // text-transform: $font-case;
  color: color('font-medium');
}


//rules
@mixin rule-heavy {
  border: 0;
  border-top: $rule-width-heavy solid color('rule-dark');
}

@mixin rule-medium {
  border: 0;
  border-top: $rule-width-medium solid color('rule-medium');
}

@mixin rule-light {
  border: 0;
  border-top: $rule-width-light solid color('rule-light');
}

//highlights
@mixin highlight-none {
  border-left: $highlight-width solid rgba(0, 0, 0, 0);
}

@mixin highlight-primary {
  border-left: $highlight-width solid color('primary');
}
