




























































@import '@/assets/css/_vars.scss';
#top-nav {
  z-index: 1000;
}
#first-top-item {
  padding-left: $leftnav-width;
}
#title {
  font-family: $font-primary;
  font-weight: normal;
  font-size: 22px;
  color: white;
}

#home-icon {
  margin-right: 0.625em;
}

#toolbar-extension {
  margin-left: 0px;
}

a {
  color: white;
  text-decoration: none;
}

.theme--light.v-toolbar {
  background-color: white;
}
