
















































































































































































































































































































































































































































































































































































@import '@/assets/css/_custom_checkbox.scss';

.draggable-group-container {
  padding-left: 20px;
}
.answer {
  align-items: center;
}
