













































.loader {
  background: #333;
  position: fixed;
  bottom: 0;
  width: 25%;
  height: 60px;
  color: #fff;

  &.compare-segments-loader {
    position: relative;
    display: block !important;
    background: #333;
    flex-basis: 60px !important;
    max-height: 60px !important;
    width: 100%;
  }

  .progress {
    width: 96%;
    height: 3px;
    background: #444;
    position: absolute;
    left: 2%;
    top: 36px;
  }

  .inner {
    width: 100%;
    background: #ffe800;
    height: 3px;
    -webkit-transition: width 2s;
    /* Safari */
    transition: width 2s;
  }
}
