@import '@/assets/css/_vars.scss';

#main-container {
  position: relative;
}

.segments {
  position: fixed;
  top: 95px;
  right: 0;
  width: 600px;
}

.segment-container {
  width: 600px;
}

.audience {
  position: fixed;
  top: 95px;
  right: 0;
  width: 600px;
}

.holder {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.panel {
  background: #222;
  width: 600px;
}

.slide-fade-enter {
  right: -600px;
}

.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-leave-active {
  transition: all 1s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  right: -600px;
  transform: translateX(600px)
}

.tag-trigger {
  background-color: #fff160;
  padding: 2px;
  padding-right: 0;
  font-size: 12px;
  font-weight: $font-bold;
  line-height: 12px;
}
