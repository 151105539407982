.alert-no-icon {
  padding-left: 8px;
}

.close-btn .v-btn__content {
  align-items: flex-end;
  justify-content: flex-end;
  background: #222222;
}

.progress {
  margin: 0 !important;
  height: 3px !important;
  margin-top: 3px;
}

.filterstitle {
  border-bottom: 1px solid #666;
}

.progress2 {
  position: absolute;
  top: 0;
  right: 0;
}

.bar {
  width: 275px;
  height: 3px;
  background: #333;
  margin-top: 10px;
  margin-left: 5px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  background: #CCC !important;
}

.custom {
  background: white !important;
  color: black !important;
  padding: 5px 15px;
}

.dragger {
  background: #555;
}

.text-black {
  color: black !important;
}

.bg-black {
  background: #222 !important;
  color: white !important;
}

.audience-container {
  position: relative;
  height: 100%;
  background: #222 !important;
}

.header {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  padding: 0;
  color: white;

  &.open {
    height: 145px;
  }
}

.footer {
  position: absolute;
  top: calc(100vh - 150px);
  z-index: 2;
  right: 0;
  width: 100%;
  height: 60px;
  // padding-bottom: 20px;
  background: #222 !important;
  background: #000;
  color: black;
}

.wrapper {
  position: relative;
  z-index: 1;
  top: 0;
  bottom: 48px;
  right: 0;
  width: 100%;
  background: #222 !important;
  background: #000;
  overflow: hidden;
  color: white;
  height: 100%;
}

.scroller {
  position: relative;
  background: #222;
  z-index: 1;
  width: 100%;
  transform: translateZ(0);
  -ms-user-select: none;
  user-select: none;
  text-size-adjust: none;
  height: calc(100vh - 125px);
  margin-top: 50px;
  //overflow-y: scroll;

  &.open {
    margin-top: 120px;
  }
}

.answer-container {
  height: 1px;
  overflow: hidden;

  &.selected {
    height: auto;
  }
}

.scroll-content-segs {
  height: 100%;
  overflow-y: auto;
}

.option-holder {
  position: relative;
  width: 100%;

  &:after {
    content: attr(data-count);
    position: absolute;
    right: 0;
    top: 0;
    color: white;
  }
}

.dialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3
}

.my-alert {
  color: #ff8773 !important;
  border-color: #222 !important;
}

.btn-black {
  background: black;
  color: white;
}

.info-icon {
  display: inline-block;
  background: url('../../../../assets/images/info-icon-black.svg') no-repeat;
  width: 30px;
  height: 30px;
  text-indent: -99px;
}

.v-card__actions {
  display: flex;
  justify-content: flex-end;
}

#my-info {
  position: relative;
  color: #FFF !important;
  border-color: #333 !important;
  margin-left: 35px !important;
  border: none !important;

  .v-alert.v-alert--outline {
    border: none !important;
  }

  .v-icon {
    display: none !important;
  }

  &:before {
    content: url('../../../../assets/images/info_white_24px.svg');
    width: 30px;
    height: 30px;
    position: absolute;
    display: inline-block;
    left: -20px;
    top: 20px;
  }
}

#my-alert {
  position: relative;
  color: #ff8773 !important;
  // border-color: #333 !important;
  margin-left: 30px !important;

  .v-alert.v-alert--outline {
    border: 1px solid #333 !important;
  }

  .v-icon {
    display: none !important;
  }

  &:before {
    content: url('../../../../assets/images/access_alarm_24px.svg');
    width: 30px;
    height: 30px;
    position: absolute;
    display: inline-block;
    left: -20px;
    top: 20px;
  }
}

#my-alert-2 {
  position: relative;
  color: #ff8773 !important;
  // border-color: #333 !important;
  margin-left: 27px !important;

  .v-alert.v-alert--outline {
    // border: 1px solid #333 !important;
  }

  .v-icon {
    display: none !important;
  }

  &:before {
    content: url('../../../../assets/images/access_alarm_24px.svg');
    width: 30px;
    height: 30px;
    position: absolute;
    display: inline-block;
    left: -20px;
    top: 15px;
  }
}

.filterstitle {
  font-size: 12px;
}
