

















































































































.info--text {
  color: #fff !important;
  caret-color: #fff !important;
}
.filter-dropdown {
  max-width: 300px;
}
.v-list__tile__title {
  font-weight: 700;
}
