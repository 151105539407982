









































































































.scroll-to {
  cursor: pointer;
}

.rolloverbtn {
  .target {
    opacity: 1;
    transition: opacity 0.5 linear;
  }

  &.inactive {
    .target {
      opacity: 0.5;
    }
  }
}

.rolloverbtn:focus {
  outline: none !important;
}
