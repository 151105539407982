


















































































































































































































































































@import '@/assets/css/_segmentFilters.scss';
@import '@/assets/css/_filterAlert.scss';
@import '@/assets/css/_custom_checkbox.scss';
@import '@/assets/css/_vars.scss';

.compare-segments-container {
  .theme--dark.v-icon {
    color: #fff !important;
  }
  .accent--text {
    color: white !important;
    caret-color: white !important;
  }
  .close-btn .v-btn__content {
    align-items: flex-end;
    justify-content: flex-end;
  }

  &#compare-segments-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);

    .checklist {
      overflow-y: scroll;
    }
  }
}
