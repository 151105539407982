


































































































































@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
label.bold {
  color: #000;
  font-weight: bold;
}
form {
  padding: 1.5em;
}
#edit-syndicated {
  @include headline-small-soft;
  font-family: 'Material Icons';
}

.form-field {
  margin-bottom: $spacing-base;
}

.card-title {
  border-bottom: 1px solid color('rule-light');
}

.segment-definition {
  @include headline-small-strong;
}
