













@import '@/assets/css/_vars.scss';

.content__wrap {
  background-color: #000;
}

.verify-container {
  width: 325px;
  position: fixed;
  top: 125px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.logo {
  padding: $spacing-base;
}
