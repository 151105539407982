





















































































































@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
label.bold {
  color: #000;
  font-weight: bold;
}
form {
  padding: 1.5em;
}
#edit-survey {
  @include headline-small-soft;
  font-family: 'Material Icons';
}

// .survey-description {
//   margin-top: 1000px;
// }
