.my-special-class {
  border-radius: 20px;
  background-color: #222 !important;
  // div[role='listitem'] {
  //   &:nth-child(2) {
  //     border-bottom: 1px solid #666;
  //   }
  // }

  .v-list {
    color: white;
    background-color: #444;
  }

  &.v-menu__content::-webkit-scrollbar {
    width: 0 !important;
  }

  .v-text-field.v-text-field--enclosed {
    margin: 6px 8px;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 0;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
    padding: 0;
  }

  .v-select__selection--comma {
    text-overflow: ellipsis;
  }

  .v-messages {
    min-height: 0;
  }

  .theme--dark.v-text-field--solo>.v-input__control>.v-input__slot {
    border-radius: 18px;
    border-color: transparent;
    border-width: 1px;
    margin-bottom: 0;
    min-height: 0;
    text-overflow: ellipsis;
    height: 36px;
    background: #444 !important;
  }

  .theme--dark.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
    border-width: 1px;
  }

  .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
    border-radius: 18px;
    border-color: #000;
    border-width: 1px;
    margin-bottom: 0;
    min-height: 0;
    text-overflow: ellipsis;
    height: 36px;
    background: #fff !important;
  }

  .theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
    border-width: 1px;
  }

  .v-list--dense .v-list__tile .v-icon.dropdown-round-item-icon {
    color: #fff;
    margin-right: 5px;
  }
}
