//color
$colors: (
  primary: #ffe800,
  secondary: #ffe800,
  background: #eeeeee,
  highlight: #fffef0,
  info: #36c2ff,
  info-light: #d5f2ff,
  info-red: #fee4d9,
  positive: #40ff44,
  negative: #ff5050,
  negative-light: #ffe2dc,
  data: (
    #3452ff,
    #8900ff,
    #00d4ae,
    #ff7400,
    #ff2bd2,
    #a8046c,
  ),
  data-light: (
    #8facff,
    #c37cff,
    #ff87e6,
    #ff6585,
    #ffb34d,
    #a5ff62,
    #62ffe3,
  ),
  data-medium: (
    #3452ff,
    #8900ff,
    #ff2bd2,
    #ff0000,
    #ff7400,
    #4f9700,
    #009c93,
  ),
  data-dark: (
    #0f2290,
    #6a1389,
    #a8046c,
    #970000,
    #b06000,
    #344d0d,
    #005d4c,
  ),
  data-filters: (
    #3452ff,
    #8900ff,
    #00d4ae,
    #ff7400,
    #ff2bd2,
    #a8046c,
    #8facff,
    #c37cff,
    #ff87e6,
    #ff6585,
    #ffb34d,
    #a5ff62,
    #62ffe3,
  ),
  data-viz: (
    #4154de,
    #ff8773,
    #f5d700,
    #2dc53c,
    #a9dbee,
    #5b3259,
    #a77aff,
    #d6cab2,
    #916e00,
    #ffa031,
    #eb7300,
    #bedfad,
    #003b7c,
    #b9afeb,
    #008960,
    #215a46,
    #fa4b00,
    #917350,
    #639dff,
    #4f320c,
    #e5bbbf,
    #872ddc,
    #791313,
    #b8a17e,
  ),
  data-filter: #3452ff,
  bg-homepage: #222222,
  bg-dark: #000000,
  bg-medium-dark: #999999,
  bg-medium: #eeeeee,
  bg-light: #ffffff,
  font-dark: #000000,
  font-medium: #666666,
  font-medium-light: #999999,
  font-light-light: #cccccc,
  font-light: #ffffff,
  rule-dark: #000000,
  rule-medium: #333333,
  rule-light: #dddddd,
  form-highlight: #03b3ff,
);

@function color($key) {
  @return map-get($colors, $key);
}

//font
$font-primary: "Helvetica Neue", Helvetica, sans-serif;
$font-size-base: 0.95em;
$font-bold: bold;
$font-regular: regular;
$font-case: capitalize;
$font-size-base-px: 16;

// @import "@/assets/fonts/souvenir-font.css";
// font homepage headlines
$font-homepage-headline: SouvenirStd-Light, sans-serif;
$homepage-font-paragraph-size: 1.2rem;
$homepage-max-width: 1440px;
//breakpoints

//borders
$corner-radius: none;
$border-primary: none;
$border-secondary: none;
$alert-border-radius: 0px;

//rules
$rule-width-heavy: 2px;
$rule-width-medium: 1px;
$rule-width-light: 1px;

//spacing
$home-section-height: 39rem;
$spacing-base: 1.5rem;
$highlight-width: 0.25rem;
$leftnav-width: 50px;
$analyze-journey-margin-top: 120px;
$horizontal-nav-height: 48px;

:export {
  //export SASS as JSON
  primary: color("primary");
  secondary: color("secondary");
  info: color("info");
  info-light: color("info-light");
  positive: color("positive");
  negative: color("negative");
  negative-light: color("negative-light");

  data: color("data");
  data-light: color("data-light");
  data-filters: color("data-filters");
  data-medium: color("data-medium");
  data-dark: color("data-dark");
  data-viz: color("data-viz");

  bg-homepage: color("bg-homepage");
  bg-dark: color("bg-dark");
  bg-medium: color("bg-medium");
  bg-light: color("bg-light");

  font-dark: color("font-dark");
  font-medium: color("font-medium");
  font-medium-light: color("font-medium-light");
  font-light-light: color("font-light-light");
  font-light: color("font-light");

  rule-dark: color("rule-dark");
  rule-medium: color("rule-medium");
  rule-light: color("rule-light");
  leftnav-width: $leftnav-width;
  analyze-journey-margin-top: $analyze-journey-margin-top;
  horizontal-nav-height: $horizontal-nav-height;
}
