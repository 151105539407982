





































































































































































































































































@import '@/assets/css/_segmentFilters.scss';
@import '@/assets/css/_filterAlert.scss';
.close-btn .v-btn__content {
  align-items: flex-end;
  justify-content: flex-end;
  background: #222;
}

.segment-container {
  .theme--dark.v-icon {
    color: #fff !important;
  }
  .accent--text {
    color: white !important;
    caret-color: white !important;
  }
  .close-btn .v-btn__content {
    align-items: flex-end;
    justify-content: flex-end;
  }
  .filterstitle {
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 30px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
