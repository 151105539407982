



















































.simplebar-content-wrapper {
  .theme--dark.v-sheet {
    background-color: #222;
  }
  .v-list.theme--dark {
    background-color: #222;
  }
}
