





















































































































@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';

.tabs-container {
  padding-left: 12px;
  .v-tabs__slider {
    height: 3px;
  }

  .v-tabs__item {
    text-transform: none;

    &:not(.v-tabs__item--active) {
      opacity: 1;
      color: color(font-medium-light);

      .svg-icon-container {
        opacity: 0.4;
      }
    }
  }
}
