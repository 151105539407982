









































































svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
