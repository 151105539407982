

















































































@import '@/assets/css/_vars.scss';
.dropdown-menu-item.icon-item {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &.border-bottom {
    border-bottom: 1px solid color(font-medium);
  }
}
