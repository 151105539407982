







































































































































































































































































































































































































































.audience-name {
  display: inline-block;
  padding: 0 10px;
}

.audience-name-container {
  display: flex;
  align-items: baseline;
}

.scroll-to {
  cursor: pointer;
}

.v-tooltip__content {
  p {
    margin-bottom: 0;
  }
}
