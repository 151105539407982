
















































































































































@import '@/assets/css/_vars.scss';
@import '@/assets/css/_journeylayout.scss';
#nav-offset {
  margin-left: $leftnav-width;
}
// .simplebar-scrollbar:before {
//   position: absolute;
//   content: '';
//   background: #fff;
//   border-radius: 7px;
//   left: 0;
//   right: 0;
//   opacity: 0;
//   transition: opacity 0.2s linear;
// }
