


















































@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
.profile-avatar.v-avatar {
  span {
    text-transform: uppercase;
    font-size: 3em;
  }
  &.nav-avatar {
    span {
      font-size: 0.75em;
    }
  }
  &.news-avatar {
    span {
      font-size: 1.5em;
    }
  }
}
