@import "@/assets/css/_vars.scss";

.segment-details-label {
  font-size: $font-size-base * 0.85;
  color: color("font-medium-light") !important;
}

.filter-btn {
  &:hover {
    color: black !important;
    background-color: white !important;
  }
}

.filterpanel {
  @import "@/assets/css/_customDropDown.scss";

  .filter-dropdown {
    border: none;
    color: white;
  }

  .v-text-field--outline > .v-input__control > .v-input__slot {
    border: none !important;
    border-radius: 30px !important;
    background: #444 !important;
    font-size: 14px !important;
    min-height: 30px !important;
  }

  .simplebar1 {
    height: 200px;
  }

  .simplebar2 {
    height: auto;
    margin-bottom: 60px;
  }

  .filter-container {
    height: 50vh;
    // padding-left: 20px;
    // padding-right: 20px;
  }

  @media (max-height: 700px) {
    .simplebar2 {
      height: 140px;
      padding-bottom: 50px;
    }
  }

  .v-expansion-panel__header,
  .v-card__text {
    background: #222222 !important;

    .question-text {
      text-align: center;
    }
  }

  .v-expansion-panel__header {
    padding-top: 0rem;
  }

  position: fixed;
  top: 96px;
  right: 0;
  height: calc(100vh - 96px);
  background: #222222;
  width: 25%;

  &.filterpanel-syndicated {
    width: 23%;
  }

  // @media (min-width: 1200px) {
  //   width: 22%;
  // }

  // @media (min-width: 1400px) {
  //   width: 23%;
  // }

  // @media (min-width: 1600px) {
  //   width: 23%;
  // }
}

.v-expansion-panel__header__icon {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.w-100 {
  width: 100%;
}

.v-card__text {
  font-size: 12px;
}

.rolloverbtn {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .target {
    opacity: 1;
    transition: opacity 0.5 linear;
  }

  &.inactive {
    .target {
      opacity: 0.5;
    }
  }
}

.rolloverbtn:focus {
  outline: none !important;
}

.bg-black {
  background: #222222;
  color: white;
}

//
.small-avatar {
  height: 40px !important;
  width: 30px !important;
  min-width: 20px !important;
}

.small-circle {
  width: 12px;
  height: 12px;
  background: #3452ff;
  display: inline-block;
  border-radius: 50%;
}
